import React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/Layout';
import Head from '../components/Head';
import Header from '../components/Header';
import {
  Title,
  Hero,
  BodyRichText,
  FoodList,
  WineList,
  Image,
  KeyInfo,
  StaticMap,
} from '../components/Review';
import { color, device } from '../constants';
const { generateReviewSchema } = require('../helpers');

const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;

const MapContainer = styled.div`
  margin-top: 86px;
  height: 70vh;
  width: 100vw;

  @media ${device.laptopL} {
    margin-top: 65px;
  }
`;

const Headline = styled.p`
  margin-left: 50px;
  min-width: 307px;
  font-size: 28px;

  @media ${device.tablet} {
    margin-left: 150px;
    min-width: 462px;
    font-size: 38px;
  }
`;

export default props => {
  const {
    analyticsId,
    citiesList,
    defaultCity,
    token,
    style,
    ...review
  } = props.pageContext;
  const {
    author,
    bodyRichText,
    categories,
    foods,
    geolocation,
    headline,
    hero,
    image,
    phoneNumber,
    price,
    publishDate,
    rating,
    streetAddress,
    suburb,
    title,
    website,
    wines,
  } = review;

  const headTitle = `${title} Review`;

  return (
    <Layout backgroundColor={color.cream2}>
      <Head
        title={headTitle}
        description={headline}
        schema={generateReviewSchema(review)}
      />
      <Header
        citiesList={citiesList}
        defaultCity={defaultCity}
        analyticsId={analyticsId}
        backgroundColor={color.white}
        textColor={color.black}
        hamburgerIconDark={true}
        sticky={true}
      />
      <Title title={title} author={author} date={publishDate} />
      <Hero image={hero.file.url} />
      <Content>
        <KeyInfo
          price={price}
          location={suburb}
          categories={categories}
          rating={rating}
          phoneNumber={phoneNumber}
          website={website}
        />
        {bodyRichText ? (
          <BodyRichText bodyRichText={bodyRichText} headline={headline} />
        ) : (
          undefined
        )}
        {wines ? <WineList wines={wines} /> : undefined}
        {image ? <Image image={image.file.url} /> : undefined}
        {foods ? <FoodList foods={foods} /> : undefined}
      </Content>
      <MapContainer>
        <Headline className="SubHeading2">Address</Headline>
        <StaticMap
          latitude={geolocation.lat}
          longitude={geolocation.lon}
          token={token}
          style={style}
          title={title}
          streetAddress={streetAddress}
        />
      </MapContainer>
    </Layout>
  );
};
